/* @import url('https://fonts.googleapis.com/css2?family=Open+Dyslexic:wght@400;700&display=swap'); */

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* global.css */

/* Miandra GD Regular */
/* @font-face {
  font-family: 'Miandra GD';
  font-style: normal;
  font-weight: 400;
  src: local('Miandra GD Regular'), local('MiandraGD-Regular'),
       url('https://fonts.gstatic.com/s/miandragd/v1/pxiEypNc_2UfrJf8d1nAoH8GNlaK.ttf') format('truetype');
} */

/* Optionally, you can include additional styles for the body or specific elements */
 
  /* font-family: 'Miandra GD', sans-serif; */
  /* global.css */

 

body {
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'PLayfair Display', serif;
}




/* global.css or in your component file */


/* global.css or in your component file */
 .mission-container {
  /* Add styles specific to the mission container */
  background-color: #f8f8f8; /* Example background color */
  padding: 20px; /* Example padding */
  border-radius: 8px; /* Example border radius for rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Example box shadow for depth */
}

/* Style the heading within the mission container */
 .mission-container h2 {
  color: black; /* Example text color for the heading */
}

/* Optional: Add styles for the paragraph within the mission container */
.mission-container p {
  margin-top: 10px; /* Example margin-top for spacing */
}

.high-contrast {
  background-color: black;
  color: white;
}
.map-container {
  width: 100%;
  height: 100vh;
}

.map {
  width: 100%;
  height: 100%;
}

.highlight {
  background-color: yellow;
}

@tailwind base;
@tailwind components;
@tailwind utilities;