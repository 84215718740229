.slide-transition {
    transition: opacity 1s ease-in-out;
  }
  
  .slide-in {
    opacity: 1;
  }
  
  .slide-out {
    opacity: 0;
  }