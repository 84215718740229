

.logo-image {
  width: 50%;
  /* height: 25%; */
}

/* Dropdown menu styles */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  display: none;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-item {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}
.menu-button {
  cursor: pointer;
}

/* Additional styling as needed */

.language-switcher {
  display: flex;
  gap: 10px; /* Adjust the spacing between buttons */
}

.language-switcher button {
  background-color: #4caf50; /* Set a background color */
  color: white; /* Set text color */
  padding: 8px 12px; /* Adjust padding */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s; /* Add a smooth transition */
}

.language-switcher button:hover {
  background-color: #45a049; /* Change background color on hover */
}
